import "./Topnav.css";
import * as React from "react";
import { Button } from "@mui/material";
import Hizzy from "../assets/Hizzy.png";
import logo from "../assets/homeSphere.jpeg";
import { useNavigate } from "react-router-dom";

export default function Topnav() {
  const navigate = useNavigate();

  const logout = async () => {
    await localStorage.removeItem("uid");
    navigate("/");
  };

  const register = async () => {
    await localStorage.removeItem("uid");
    navigate("/register");
  };

  return (
    <div className="homeSphere-Nav">
      {window.location.pathname.toLowerCase() === "/" && (
        <Button
          type="submit"
          variant="contained"
          onClick={() => navigate("/login")}
          style={{
            width: 120,
            height: "40px",
            fontWeight: "bold",
            borderRadius: "5px",
            textTransform: "none",
            backgroundColor: "black",
          }}
          className="hs-button"
        >
          Login
        </Button>
      )}
      {window.location.pathname.toLowerCase() === "/register" && (
        <Button
          type="submit"
          variant="contained"
          onClick={() => navigate("/login")}
          style={{
            width: 120,
            height: "40px",
            fontWeight: "bold",
            borderRadius: "5px",
            textTransform: "none",
            backgroundColor: "black",
          }}
        >
          Login
        </Button>
      )}
      {window.location.pathname.toLowerCase() === "/login" && (
        <Button
          type="submit"
          onClick={register}
          variant="contained"
          className="hs-button"
        >
          Register
        </Button>
      )}
      {window.location.pathname.toLowerCase() === "/link" && (
        <Button
          type="submit"
          onClick={logout}
          variant="contained"
          className="hs-button"
        >
          Logout
        </Button>
      )}
      <img src={logo} className="homeSphere-logo" alt="homeshere logo" />
      <div className="powerBox">
        <span className="powerText">Powered by</span>
        <img src={Hizzy} className="Hizzy-logo" alt="Hizzy Logo" />
      </div>
    </div>
  );
}
