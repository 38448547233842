import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import {
  doc,
  setDoc,
  addDoc,
  getDoc,
  query,
  where,
  getDocs,
  updateDoc,
  collection,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import {
  signOut,
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA-9gSa8bB9c34DwUKxTwJ2bJ-azaHGq9c",
  authDomain: "fir-auth-1d4b6.firebaseapp.com",
  projectId: "fir-auth-1d4b6",
  storageBucket: "fir-auth-1d4b6.appspot.com",
  messagingSenderId: "528286069002",
  appId: "1:528286069002:web:a7b6dc4c1c60a44eea8af3",
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  uploadBytesResumable,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  getDownloadURL,
  uploadBytes,
  onSnapshot,
  collection,
  updateDoc,
  database,
  storage,
  getDocs,
  signOut,
  setDoc,
  getDoc,
  addDoc,
  query,
  where,
  auth,
  doc,
  ref,
};
