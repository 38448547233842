import {
  Box,
  Grid,
  Button,
  Checkbox,
  TextField,
  Container,
} from "@mui/material";
import {
  doc,
  auth,
  setDoc,
  database,
  createUserWithEmailAndPassword,
} from "../db/firebase";
import "./register.css";
import React from "react";
import Swal from "sweetalert2";
import Topnav from "../components/Topnav";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";

function RegisterPage() {
  const navigate = useNavigate();
  const [zip, setZip] = React.useState("");
  const [city, setCity] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [buisnessName, setBuisnessName] = React.useState("");
  const [buisnessWebsite, setBuisnessWebsite] = React.useState("");

  const CheckUser = useCallback(async () => {
    const id = await localStorage.getItem("uid");
    if (id) navigate("/link");
  }, [navigate]);

  useEffect(() => {
    CheckUser();
  }, [CheckUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !zip ||
        !city ||
        !state ||
        !email ||
        !phone ||
        !address ||
        !lastName ||
        !password ||
        !firstName ||
        !buisnessName ||
        !buisnessWebsite
      ) {
        Swal.fire({
          title: "Error!",
          text: "Please fill all the fields!",
          icon: "error",
        });
      } else {
        setLoading(true);
        await createUserWithEmailAndPassword(auth, email, password)
          .then(async (user) => {
            let obj = {
              zip,
              city,
              email,
              state,
              address,
              buisnessName,
              buisnessWebsite,
              lname: lastName,
              fname: firstName,
              uid: user.user.uid,
              phoneNumber: phone,
              company: "homesphere",
              createdAt: Date.now(),
            };
            setDoc(doc(database, "builders", user.user.uid), obj)
              .then(async () => {
                await localStorage.setItem("uid", user.user.uid);
                await fetch(
                  "https://us-central1-fir-auth-1d4b6.cloudfunctions.net/builders/homesphareBuilderSignup",
                  {
                    method: "POST",
                    headers: {
                      "Content-type": "application/json;charset=UTF-8",
                      "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify({
                      email: email,
                      uid: user.user.uid,
                      builderName: `${firstName} ${lastName}`,
                    }),
                  }
                );
                setLoading(false);
                Swal.fire({
                  text: `Thanks ${firstName} ${lastName}! You should recive a text and an email with your custom HomeSphere link.`,
                  icon: "success",
                  title: "Success",
                  confirmButtonText: "Go To HomeSphere",
                });
                navigate("/link");
              })
              .catch((error) => {
                setLoading(false);
                Swal.fire({ title: "Error!", text: error, icon: "error" });
              });
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire({ title: "Error!", text: error, icon: "error" });
          });
      }
    } catch (e) {
      setLoading(false);
      Swal.fire({ title: "Error!", text: e, icon: "error" });
    }
  };

  return (
    <div>
      <Topnav />
      <Container className="register-box" maxWidth="lg">
        <p className="register-heading">Create your builder profile</p>
        <p className="register-desc">
          Fill out the information below to set up your HomeSphere custom link
        </p>
        <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
          <Grid display={"flex"}>
            <TextField
              style={{ marginRight: "10px" }}
              margin="normal"
              value={firstName}
              required
              fullWidth
              onChange={(e) => setFirstName(e.target.value)}
              id="fname"
              label="First Name"
              name="fname"
              autoFocus
            />
            <TextField
              style={{ marginLeft: "10px" }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              margin="normal"
              required
              fullWidth
              name="lname"
              label="Last Name"
              type="text"
              id="lname"
            />
          </Grid>
          <Grid display={"flex"}>
            <TextField
              value={email}
              margin="normal"
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              style={{ marginRight: "10px" }}
            />
            <TextField
              style={{ marginLeft: "10px" }}
              value={phone}
              margin="normal"
              required
              fullWidth
              name="phoneNumber"
              label="Phone Number"
              onChange={(e) => setPhone(e.target.value)}
              type="text"
              id="phoneNumber"
            />
          </Grid>

          <Grid
            sx={{
              marginTop: 3,
              marginBottom: 1,
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Checkbox
              style={{ margin: 0, marginRight: 5, marginTop: 10, padding: 0 }}
            />
            <p className="privacyText">
              I authorize Hizzy and it's partners to send me SMS texts and
              e-mails on updates to my account, services and installation from
              national providers.&nbsp;
              <span className="privacyTextBlue">Privacy Policy&nbsp;</span>
              &&nbsp;
              <span className="privacyTextBlue">Terms of Use</span>
            </p>
          </Grid>

          <Grid display={"flex"}>
            <TextField
              required
              fullWidth
              value={buisnessName}
              onChange={(e) => setBuisnessName(e.target.value)}
              autoFocus
              id="buisness"
              name="buisness"
              margin="normal"
              label="Buisness Name"
              style={{ marginRight: "10px" }}
            />
            <TextField
              required
              fullWidth
              autoFocus
              margin="normal"
              value={buisnessWebsite}
              onChange={(e) => setBuisnessWebsite(e.target.value)}
              id="buisnessWeb"
              name="buisnessWeb"
              label="Buisness Website"
              style={{ marginLeft: "10px" }}
            />
          </Grid>

          <p className="register-sub-heading">Home address</p>
          <Grid display={"flex"}>
            <TextField
              required
              fullWidth
              autoFocus
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              id="address"
              name="address"
              margin="normal"
              label="Address"
              style={{ marginRight: "10px" }}
            />
            <TextField
              required
              fullWidth
              id="city"
              name="city"
              type="text"
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              margin="normal"
              style={{ marginLeft: "10px" }}
            />
          </Grid>
          <Grid display={"flex"}>
            <TextField
              required
              value={state}
              onChange={(e) => setState(e.target.value)}
              fullWidth
              autoFocus
              id="state"
              name="state"
              label="State"
              margin="normal"
              style={{ marginRight: "10px" }}
            />
            <TextField
              id="zip"
              required
              fullWidth
              autoFocus
              name="zip"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
              margin="normal"
              label="Zip Code"
              style={{ marginLeft: "10px" }}
            />
          </Grid>

          {/* <p className="register-sub-heading">Question here....</p>
          <TextField
            required
            autoFocus
            id="question"
            name="question"
            margin="normal"
            label="Type Question"
            style={{ width: "49.5%" }}
          /> */}

          <p className="register-sub-heading">Password</p>
          <TextField
            required
            autoFocus
            id="password"
            name="password"
            margin="normal"
            value={password}
            label="Type Password"
            style={{ width: "49.5%" }}
            onChange={(e) => setPassword(e.target.value)}
          />

          <br />
          <Button
            type="submit"
            loading={loading}
            disabled={loading}
            variant="contained"
            onClick={handleSubmit}
            style={{
              width: 200,
              color: "white",
              height: "45px",
              marginTop: "50px",
              borderRadius: "5px",
              marginBottom: "100px",
              backgroundColor: "black",
            }}
          >
            Done
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default RegisterPage;
