import "./register.css";
import React from "react";
import Swal from "sweetalert2";
import Hizzy from "../assets/Hizzy.png";
import Topnav from "../components/Topnav";
import { useNavigate, Link } from "react-router-dom";
import { auth, sendPasswordResetEmail } from "../db/firebase";
import { Box, Button, TextField, Container, Grid } from "@mui/material";

function RegisterPage() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email) {
      Swal.fire({
        title: "Error!",
        text: "Please Enter Email Address!",
        icon: "error",
      });
    } else {
      setLoading(true);
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          navigate("/login");
          Swal.fire({
            title: "Email Sent!",
            text: "Please Check Your Email To Reset Password!",
            icon: "success",
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({ title: "Error!", text: error, icon: "error" });
        });
    }
  };

  return (
    <div>
      <Topnav />
      <Container className="login-box" maxWidth="md">
        <p className="register-heading">Reset Password</p>
        <p className="register-desc">
          Reset Password to get your custom Builder Link.
        </p>
        <Box noValidate sx={{ mt: 8 }}>
          <TextField
            value={email}
            required
            fullWidth
            id="email"
            autoFocus
            name="email"
            margin="normal"
            label="Email Address"
            onChange={(e) => setEmail(e.target.value)}
          />

          <Grid sx={{ textAlign: "left", color: "black" }}>
            <span className="privacyText">
              Remember Password?{" "}
              <Link to={"/login"} className="privacyTextBlue">
                Singin
              </Link>
            </span>
          </Grid>

          <br />
          <Button
            fullWidth
            type="submit"
            loading={loading}
            disabled={loading}
            variant="contained"
            onClick={handleLogin}
            style={{
              height: "45px",
              borderRadius: "5px",
              marginBottom: "100px",
              backgroundColor: "black",
            }}
          >
            Reset Password
          </Button>
        </Box>
        <div className="dashboardPowerBox">
          <span className="dashboardPowerText">Powered by</span>
          <img
            src={Hizzy}
            alt="Hizzy-dashboard-logo"
            className="Hizzy-dashboard-logo"
          />
        </div>
      </Container>
    </div>
  );
}

export default RegisterPage;
