import {
  doc,
  auth,
  getDoc,
  database,
  signInWithEmailAndPassword,
} from "../db/firebase";
import "./register.css";
import React from "react";
import Swal from "sweetalert2";
import Hizzy from "../assets/Hizzy.png";
import Topnav from "../components/Topnav";
import { useNavigate, Link } from "react-router-dom";
import { Box, Button, TextField, Container, Grid } from "@mui/material";

function RegisterPage() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        Swal.fire({
          title: "Error!",
          text: "Please fill all the fields!",
          icon: "error",
        });
      } else {
        setLoading(true);
        signInWithEmailAndPassword(auth, email, password)
          .then(async (user) => {
            let docSnap = await getDoc(
              doc(database, "builders", user.user.uid)
            );
            if (docSnap.exists()) {
              await localStorage.setItem("user", user.user.uid);
              setLoading(false);
              navigate("/link");
            } else {
              setLoading(false);
              Swal.fire({
                icon: "error",
                title: "Error!",
                text: "this email is not registered with this builder account",
              });
            }
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire({ title: "Error!", text: error, icon: "error" });
          });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({ title: "Error!", text: error, icon: "error" });
    }
  };

  return (
    <div>
      <Topnav />
      <Container className="login-box" maxWidth="md">
        <p className="register-heading">Builder Login</p>
        <p className="register-desc">Login to get your custom Builder Link.</p>
        <Box noValidate sx={{ mt: 8 }}>
          <TextField
            value={email}
            required
            fullWidth
            id="email"
            autoFocus
            name="email"
            margin="normal"
            label="Email Address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            required
            fullWidth
            type="text"
            id="password"
            margin="normal"
            name="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Grid sx={{ textAlign: "left" }}>
            <Link to={"/forgetPsw"} className="forgetText">
              Forget Password
            </Link>
          </Grid>

          <br />
          <Button
            fullWidth
            type="submit"
            loading={loading}
            disabled={loading}
            variant="contained"
            onClick={handleLogin}
            style={{
              height: "45px",
              borderRadius: "5px",
              marginBottom: "100px",
              backgroundColor: "black",
            }}
          >
            Login
          </Button>
        </Box>
        <div className="dashboardPowerBox">
          <span className="dashboardPowerText">Powered by</span>
          <img
            src={Hizzy}
            alt="Hizzy-dashboard-logo"
            className="Hizzy-dashboard-logo"
          />
        </div>
      </Container>
    </div>
  );
}

export default RegisterPage;
