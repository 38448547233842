import "./Dashboard.css";
import Topnav from "components/Topnav";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/Hizzy-Homesphere.png";
import { Container, Button } from "@mui/material";

export default function Dashboard() {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    navigate("/register");
  };
  
  const CheckUser = useCallback(async () => {
    const id = await localStorage.getItem("uid");
    if (id) navigate("/link");
  }, [navigate]);

  useEffect(() => {
    CheckUser();
  }, [CheckUser]);

  return (
    <div>
      <Topnav />
      <Container maxWidth="md" className="dashboardView">
        <p className="dashboard-heading">Getting started with Hizzy!</p>
        <p className="dashboard-desc">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui pariatur
          beatae quo, vitae consequuntur laborum dolor quod rem aspernatur nobis
          quaerat dolorum sapiente nulla reprehenderit veritatis eligendi
          exercitationem perferendis aut?
          <br />
          <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui pariatur
          beatae quo, vitae consequuntur laborum dolor quod rem aspernatur nobis
          quaerat dolorum sapiente nulla reprehenderit veritatis eligendi
          exercitationem perferendis aut?
        </p>
        <div className="dashboard-body-Img">
          <img src={logo} className="dashboard-img" alt="dashboard logo" />
        </div>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          style={{
            width: 200,
            height: "45px",
            marginTop: "50px",
            fontWeight: "bold",
            borderRadius: "5px",
            marginBottom: "30px",
            backgroundColor: "black",
          }}
        >
          Get Started!
        </Button>
      </Container>
    </div>
  );
}
