import "./App.css";
import Link from "./pages/Link";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import ForgetPsw from "./pages/ForgetPsw";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/link" element={<Link />} />
        <Route path="/login" element={<Login />} />
        <Route exact path="/" element={<Dashboard />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgetPsw" element={<ForgetPsw />} />
      </Routes>
    </BrowserRouter>
  );
}
