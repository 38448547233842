import "./Link.css";
import Topnav from "components/Topnav";
import React, { useEffect } from "react";
import Logo from "../assets/unyte-maddie.png";
import { getDoc, doc, database } from "../db/firebase";
import { Container, Chip, Button } from "@mui/material";

function Link() {
  const [uid, setUid] = React.useState(null);
  const [user, setUser] = React.useState(null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const id = await localStorage.getItem("uid");
    if (id) {
      setUid(id);
      getDoc(doc(database, "builders", id)).then((doc) => setUser(doc.data()));
    }
  };

  const handleCopy = () => {};

  return (
    <div>
      <Topnav />
      <Container
        className="login-box"
        maxWidth="lg"
        style={{
          height: "90vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img src={Logo} alt="logo" style={{ width: 150, height: 150 }} />
        <p id="home-heading">HomeSphere Custom Link</p>
        <p id="home-desc">
          Hello {user?.fname} {user?.lname}, Welcome back! here is your custom
          link
        </p>
        <Chip
          variant="outlined"
          label={`https://app.myhizzy.com/register?builder=${uid}&company=homesphere`}
          style={{
            width: "700px",
            padding: "20px",
            fontSize: "16px",
            marginTop: "40px",
            cursor: "pointer",
            borderRadius: "50px",
          }}
        />
        <br />
        <Button
          type="submit"
          variant="contained"
          onClick={handleCopy}
          style={{
            width: 200,
            height: "45px",
            borderRadius: "5px",
            marginBottom: "50px",
            backgroundColor: "black",
          }}
        >
          Copy
        </Button>
      </Container>
    </div>
  );
}

export default Link;
